<!--
 * @Author: 咸鱼
 * @Date: 2020-05-27 15:38:44
 * @LastEditTime: 2020-09-11 17:07:51
 * @LastEditors: 咸鱼
 * @Description:
 * @FilePath: /vue-element-wyh/src/views/market/lucky/records.vue
 * @可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div>
    <table-page dataName="list"
                ref="table"
                @on-success="fetchSuccess"
                :params="{activity_id:$route.query.id}"
                :search="search"
                :request="questionLuckyLogs">
      <template slot="button">
        <FileExport :params="exportParams"
                    v-if="exportParams">导出数据</FileExport>
      </template>
      <el-table-column prop="user_name"
                       show-overflow-tooltip
                       label="姓名">
      </el-table-column>
      <el-table-column prop="user_tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="user_id_code"
                       show-overflow-tooltip
                       label="身份证号">
      </el-table-column>
      <el-table-column prop="user_openid"
                       show-overflow-tooltip
                       label="OPENID">
      </el-table-column>
      <el-table-column prop="prize_name"
                       show-overflow-tooltip
                       label="奖品名称">
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="中奖时间">
      </el-table-column>
      <el-table-column prop="marketer_name"
                       show-overflow-tooltip
                       label="关联员工">
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="预约网点">
        <template slot-scope="scope" v-if="scope.row.postal_area">
          <span>{{`${scope.row.postal_area.province || ''}${scope.row.postal_area.city || ''}${scope.row.postal_area.district || ''}${scope.row.postal_area.net || ''}`}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="areas_name"
                       show-overflow-tooltip
                       label="员工所属网点">
        <template slot-scope="scope">
          <span>{{`${scope.row.marketer_province || ''}${scope.row.marketer_city || ''}${scope.row.marketer_district || ''}${scope.row.marketer_net || ''}`}}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="备注">
        <template slot-scope="scope">
          <span>{{scope.row.err_msg==='SUCCESS' ? '':scope.row.err_msg}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       width="200px">
        <template slot-scope="scope">
          <el-button type="text"
                     class="mr-10"
                     v-if="scope.row.status === 'FAIL' && scope.row.prize_type === 'ENVELOPE'"
                     @click="againReceive(scope.row)">补发</el-button>
        </template>
      </el-table-column>
    </table-page>
  </div>

</template>

<script>
import TablePage from '@/components/TablePage'
import FileExport from '@/components/FileExport'
import { questionLuckyLogs, luckyReceiveEnvelope } from '@/api/market'
export default {
  name: 'LuckyRecords',
  data () {
    return {
      questionLuckyLogs,
      dialogVisible: false,
      luckyQrcodesExport_url: '',
      luckyLogExport_url: '',
      search: [
        { type: 'area' },
        {
          type: 'input',
          name: '手机号码',
          placeholder: '请输入手机号码',
          key: 'tel',
          value: ''
        }
      ],
      exportParams: null
    }
  },
  components: {
    TablePage,
    FileExport
  },
  methods: {
    fetchSuccess (e) {
      this.exportParams = e.export
    },
    async againReceive (item) {
      this.$confirm('确认补发？', {
        type: 'warning'
      }).then(async () => {
        const res = await luckyReceiveEnvelope({
          log_id: item.id
        })
        if (res.meta.code === 0) {
          this.$message.success('补发成功')
          this.dialogVisible = false
          this.$refs.table.fetch()
        } else {
          this.$message.error(res.meta.msg)
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
